const fadeDuration = Math.ceil(sampleRate * 0.004);

registerProcessor(
  'SampleSlicerProcessor',
  class SampleSlicerProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'clock',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'reset',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'start',
          defaultValue: 0,
          min: 0,
          max: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'length',
          defaultValue: 1,
          min: 0,
          max: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'detune',
          defaultValue: 0,
          automationRate: 'k-rate',
        },
      ];
    }

    slicePoints: number[];
    buffer: Float32Array | null;
    lastClock = 0;
    lastReset = 0;
    slicePosition = 0;
    position = 0;
    endPosition = 0;

    constructor(options: AudioWorkletNodeOptions) {
      super(options);
      this.buffer = options.processorOptions.buffer;
      this.slicePoints = options.processorOptions.slicePoints;
      this.port.onmessage = ({ data: [type, value] }) => {
        switch (type) {
          case 'buffer':
            this.buffer = value;
            this.position = Infinity;
            break;
          case 'slicePoints':
            this.slicePoints = value;
            break;
          default:
            throw new Error('unrecognized message');
        }
      };
    }

    process(
      _: Float32Array[][],
      [[output]]: Float32Array[][],
      {
        clock: [clock],
        reset: [reset],
        start: [start],
        length: [length],
        detune: [detune],
      }: { [param: string]: Float32Array },
    ): boolean {
      if (this.buffer === null) return true;
      if (this.slicePoints.length === 0) return true;

      let firstSliceIndex = 0;
      while (this.slicePoints[firstSliceIndex + 1] <= start) {
        firstSliceIndex++;
      }
      let sliceLength = 0;
      let lastSlice = this.slicePoints[firstSliceIndex];
      while (length > 0) {
        const index = firstSliceIndex + sliceLength + 1;
        const wrap = Math.floor(index / this.slicePoints.length);
        const slice = wrap + this.slicePoints[index % this.slicePoints.length];
        length -= slice - lastSlice;
        lastSlice = slice;
        sliceLength += 1;
      }

      if (this.lastReset <= 0 && reset > 0) {
        this.slicePosition = firstSliceIndex;
        this.position =
          this.slicePoints[this.slicePosition] * this.buffer.length;
        this.endPosition =
          (this.slicePoints[this.slicePosition + 1] ?? 1) * this.buffer.length;
        this.port.postMessage(['position', this.slicePosition]);
      } else if (this.lastClock <= 0 && clock > 0) {
        this.slicePosition =
          ((this.slicePosition - firstSliceIndex + 1) % sliceLength) +
          firstSliceIndex;
        this.position =
          this.slicePoints[this.slicePosition] * this.buffer.length;
        this.endPosition =
          (this.slicePoints[this.slicePosition + 1] ?? 1) * this.buffer.length;
        this.port.postMessage(['position', this.slicePosition]);
      }
      this.lastClock = clock;
      this.lastReset = reset;

      if (this.position > this.endPosition + fadeDuration) return true;

      const rate = Math.pow(2, detune / 1200);
      for (let i = 0; i < 128; i++) {
        this.position += rate;
        const readIndexA = Math.floor(this.position);
        const readIndexB = Math.ceil(this.position);
        const linearInterpolationFactorA = this.position - readIndexA;
        const linearInterpolationFactorB = 1 - linearInterpolationFactorA;

        const valueA = this.buffer[readIndexA];
        if (valueA === undefined) break;

        const valueB = this.buffer[readIndexB] ?? 0;

        const fadeFactor =
          1 - Math.max(0, this.position - this.endPosition) / fadeDuration;

        output[i] =
          (valueA * linearInterpolationFactorA +
            valueB * linearInterpolationFactorB) *
          fadeFactor;
      }

      return true;
    }
  },
);
